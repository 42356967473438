export enum UserSettingsKinds {
  /**
   * Eager-loaded user settings
   *
   * These settings are required when the app first loaded or cannot be easily lazy loaded.
   */
  PIPELINE_OPTIONS = 'pipelineOptions',
  TABLE_PREFERENCES = 'tablePreferences',
  VIEWER_STATE = 'viewerState',
  SEQUENCE_VIEWER_PREFERENCES = 'sequenceViewerPreferences',

  /**
   * Eager-loaded in background user settings
   *
   * These settings are not required immediately when the app first loaded but cannot be lazily loaded since they
   * create a negative impact on UX (layout change suddenly when user settings arrive, etc.)
   */
  FORM_STATE = 'formState',

  /**
   * Lazy-loaded user settings
   *
   * These settings can be lazy loaded since they are only needed when a specific component is loaded with some specific
   * conditions. For example, only 1 sequence viewer preference user setting is needed when the sequence viewer
   * is loaded for each document.
   */
  TABLE_STATE = 'tableState',
  DISMISSIBLE_STATE = 'dismissibleState', // See DismissibleDirective for usage
  UI_SETTINGS = 'uiSettings',
  GRAPH_SIDEBAR_OPTIONS = 'graphSidebarOptions',
}
